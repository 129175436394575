.om__VIOS {
    font-family: var(--font-family);
    background-color: var(--color-white);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 3rem;
    padding: var(--padding-up-down) var(--base-margin);
}

.om__left-column {
    flex: 1;
}

.om_VIOS_titel {
    font-size: 36px;
    font-weight: bold;
    background: var(--color-footer); /* Apply gradient as background */
    background-clip: text; /* Clip background to text */
    -webkit-background-clip: text; /* For Safari */
    -webkit-text-fill-color: transparent; /* Make text color transparent */
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    margin-bottom: 1.25rem;
}

.om__VIOS__text {
    font-size: 15px;
    line-height: 2;
}

.om__right-column {
    flex: 1;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
}

.om__right-column img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3)); /* Apply drop shadow */
}

@media screen and (max-width: 1500px) {
    .om__VIOS {
        padding: var(--padding-up-down) 7rem;
    }
}


@media screen and (max-width: 1050px) {
    .om__VIOS {
        padding: var(--padding-up-down) 5rem;
        grid-template-columns: 5fr 3fr;
    }

    .om__right-column img {
        max-width: 100%;
        max-height: 100%;
    }

    .om__VIOS__text {
        font-size: 13px;
    }

    .om_VIOS_titel {
        font-size: 32px;
    }

}

@media screen and (max-width: 1000px) {
    .om__VIOS {
        grid-template-columns: 1fr;
    }

    .om__left-column {
        padding-bottom: 3rem;
    }

    .om__VIOS__text {
        font-size: 13px;
    }

    .om_VIOS_titel {
        font-size: 32px;
    }

    .om__right-column img {
        max-width: 90%;
        max-height: 90%;
    }

}

@media screen and (max-width: 700px) {

    .om__VIOS {
        padding: var(--padding-up-down) 1rem;
    }

    .om__VIOS__text {
        font-size: 12px;
    }

    .om_VIOS_titel {
        font-size: 25px;
    }

    .om__right-column img {
        max-width: 90%;
        max-height: 90%;
    }

}
