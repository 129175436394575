.cta-container {
    background-color: var(--color-white);
    padding: var(--padding-up-down) 22rem;
  }

.people_presentation {
    background-color: var(--color-bg);
    padding: 50px 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 50px;
}

.people_presentation h1 {
    font-size: 32px;
    color: var(--color-footer);
    font-family: var(--font-family);
    margin-bottom: 50px;
}

.person_person1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    text-align: left;
    margin-bottom: 50px;
}

.person_person1 img {
    margin-right: 50px;
}

.person_person2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    text-align: right;
    margin-bottom: 50px;
}

.person_person2 img {
    margin-left: 50px;
}

.presentation_description {
    font-size: 16px;
    color: var(--color-footer);
    line-height: 2.2;
    font-style: italic;
}

.round_picture_pres {
    border-radius: 50%;
    width: 250px;
}

.cta-people-pres {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cta-people-pres .cta-people-pres-title {
    font-size: 18px;
    color: var(--color-footer);
    margin-bottom: 50px;
}

.cta-container .cta-button {
    width: 200px;
    background-color: transparent;
    color: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 10px 30px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 800;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-container .cta-button:hover {
    background-color: var(--color-footer);
    color: var(--color-bg);
}

@media screen and (max-width: 1500px) {
    .cta-container {
        padding: var(--padding-up-down) 7rem;
    }
}

@media screen and (max-width: 1050px) {

    .cta-container {
        padding: var(--padding-up-down) 5rem;
    }

    .people_presentation {
        padding: 50px 50px; /* Reduce padding for smaller screens */
    }

    .people_presentation h1 {
        font-size: 25px; /* Reduce font size */
        margin-bottom: 50px; /* Reduce margin bottom */
    }

    .person_person1,
    .person_person2 {
        flex-direction: column; /* Stack images and text vertically */
        align-items: center; /* Center items horizontally */
        text-align: center;
        margin-bottom: 50px; /* Reduce margin between person sections */
    }

    .person_person2 {
        flex-direction: column-reverse;
    }

    .person_person1 img,
    .person_person2 img {
        margin: 0; /* Reset image margin */
        margin-bottom: 25px;
    }

    .round_picture_pres {
        width: 200px; /* Reduce image size */
    }

    .presentation_description {
        font-size: 14px; /* Reduce font size */
    }

    .cta-people-pres .cta-people-pres-title {
        font-size: 16px; /* Reduce font size */
        margin-bottom: 30px; /* Reduce margin bottom */
    }

    .cta-container .cta-button {
        width: 200px; /* Reduce button width */
        font-size: 16px; /* Reduce font size */
    }
}

@media screen and (max-width: 700px) {

    .cta-container {
        padding: var(--padding-up-down) 1rem;
    }

    .cta-container h1 {
        font-size: 20px; /* Reduce font size */
    }

    .presentation_description {
        font-size: 12px; /* Further reduce font size */
    }

    .round_picture_pres {
        width: 200px; /* Further reduce image size */
    }

    .people_presentation {
        padding: 40px 20px; /* Further reduce padding */
    }

    .cta-people-pres .cta-people-pres-title {
        font-size: 14px; /* Further reduce font size */
        margin-bottom: 15px; /* Further reduce margin bottom */
    }

    .cta-container .cta-button {
        width: 180px; /* Further reduce button width */
        font-size: 16px; /* Further reduce font size */
    }

    .person_person1 img,
    .person_person2 img {
        margin: 0; /* Reset image margin */
        margin-bottom: 15px;
    }
}
