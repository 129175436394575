* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
    font-family: var(--font-family);
}

a {
    color: unset;
    text-decoration: none;
}

.bar {
    content: "";
    display: block;
    width: 50px; /* Adjust the width of the line as needed */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: var(--color-footer); /* Adjust the color of the line as needed */
    margin-bottom: 20px; /* Adjust the spacing between the bar and the content as needed */
    border-radius: 20px;
  }

.bar.white {
    background-color: var(--color-bg);
    width: 100px;
    height: 1px;
    margin-bottom: 10px;
  }

.picture__bg {
    position: relative;
    background-size: cover; /* Adjusts the size of the background image */
    background-position: center; /* Positions the background image */
    height: 100vh; /* Set the header height to cover the entire viewport vertically */
}

.picture__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 27, 36, 0.5);
  z-index: 1;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
  

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 1050px) {

  .bar {
    height: 3px;
  }
  
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }

    .bar {
      height: 2px;
    }
    
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
    
}