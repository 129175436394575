.VIOS__header__content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-family: var(--font-family);
    text-align: center;
    padding: var(--padding-up-down) var(--padding-left-right);
}

.header__text {
    font-weight: 50;
    color: var(--color-bg);
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 40px;
    position: relative;
}

.header__sub__text {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    position: relative;
    margin-top: 0.7rem;
    color: var(--color-bg);
}

@media screen and (max-width: 1000px) {

    .VIOS__header__content {
        padding: var(--padding-up-down) 0;
    }


}
  
@media screen and (max-width: 800px) {

    .header__text {
        font-size: 1.7rem;
    }

    .header__sub__text {
        font-size: 1.0rem;
    }

}

@media screen and (max-width: 550px) {

    .header__text {
        font-size: 1.2rem;
    }

    .header__sub__text {
        font-size: 0.8rem;
    }
}