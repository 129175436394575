.VIOS__price__prop {
    padding: var(--padding-up-down) var(--base-margin);
    background-color: #f9f9f9;
    text-align: center;
  }

.VIOS__price__prop h2{
    color: var(--color-footer);
    font-weight: 900;
    padding: 0 20px;
}

.bar-center {
    content: "";
    display: block;
    width: 50px; /* Adjust the width of the line as needed */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: var(--color-footer); /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the item horizontally */
    margin-top: 30px; /* Adjust the spacing between the bar and the content as needed */
    border-radius: 10px;
}

.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjust spacing between sliders */
    text-align: center;
    padding: 5rem 10rem;
}

.slider-item {
    position: relative;
    width: calc(33% - 10px); /* Adjust width to fit three sliders */
}

.prop-circle {
    position: absolute;
    top: -30px; /* Adjust position as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 180px; /* Adjust circle diameter as needed */
    height: 180px; /* Adjust circle diameter as needed */
    border-radius: 50%;
    background-color: var(--color-bg); /* Adjust circle color as needed */
    z-index: 3;
}

.slider-contents {
    position: relative;
    z-index: 4;
}

.prop-icon {
    font-size: 50px;
    color: var(--color-footer);
}

.prop-number {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 50px;
}
  
.slider-item p {
    margin-bottom: 10px;
}

/* SLIDER */

.VIOS-slider {
	color: var(--color-footer);
    width: 60%;
	--thumb-height: 1.125em;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 180%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}

/* === range commons === */
.VIOS-slider {
	position: relative;
	background: #fff0;
	overflow: hidden;
}

.VIOS-slider:active {
	cursor: grabbing;
}

.VIOS-slider:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}


/* === Webkit specific styles === */
.VIOS-slider,
.VIOS-slider::-webkit-slider-runnable-track,
.VIOS-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

.VIOS-slider::-webkit-slider-runnable-track,
.VIOS-slider::-webkit-slider-thumb {
	position: relative;
}

.VIOS-slider::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;
    
    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
        50% calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));

    filter: brightness(100%);
    clip-path: polygon(
        100% -1px,
        var(--clip-edges) -1px,
        0 var(--clip-top),
        -100vmax var(--clip-top),
        -100vmax var(--clip-bottom),
        0 var(--clip-bottom),
        var(--clip-edges) 100%,
        var(--clip-further) var(--clip-further)
    );
}

.VIOS-slider:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

.VIOS-slider:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

.VIOS-slider::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

.VIOS-slider:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
.VIOS-slider,
.VIOS-slider::-moz-range-track,
.VIOS-slider::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

.VIOS-slider::-moz-range-track,
.VIOS-slider::-moz-range-thumb,
.VIOS-slider::-moz-range-progress {
	background: #fff0;
}

.VIOS-slider::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

.VIOS-slider:active::-moz-range-thumb {
	cursor: grabbing;
}

.VIOS-slider::-moz-range-track {
	width: 60%;
	background: var(--track-color);
}

.VIOS-slider::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

.VIOS-slider::-moz-range-track,
.VIOS-slider::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

.VIOS-slider::-moz-range-thumb,
.VIOS-slider::-moz-range-progress {
	filter: brightness(100%);
}

.VIOS-slider:hover::-moz-range-thumb,
.VIOS-slider:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

.VIOS-slider:active::-moz-range-thumb,
.VIOS-slider:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

.VIOS-slider:disabled::-moz-range-thumb {
	cursor: not-allowed;
}

/* === SLIDER END === */
  
.VIOS-annual-revenue {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}

.annual-revenue {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center items horizontally */
    justify-content: center;
    width: 100%; /* Ensure the item occupies full width */
    margin-top: 40px;
}

.container-input-revenue {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container-input-revenue .input-revenue {
    margin-left: 2rem;
    margin-right: 0.3rem;
    font-family: var(--font-family);
    font-size: 0.9rem;
    padding: 0.5rem 0.5rem;
}

.VIOS-offer-form {
    margin-top: 100px;
    padding: 0 120px;
}

.form-group-price {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-group-price label {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    margin-left: 1px;
}

.form-group-price input,
.form-group-price textarea {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-top: 3px;
}

.cta-button-offert {
    width: 100%;
    padding: 10px 30px;
    background-color: var(--color-footer);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 50px;
    font-size: large;
}
  
.cta-button-offert:hover {
    background-color: var(--color-hover);
}

.show-price {
    margin-top: 50px;
}

.show-price p {
    font-size: 1rem;
    font-weight: 700;
}

.show-price h2 {
    font-size: 2rem;
    font-weight: 1000;
    color: var(--color-hover)
}

/* Hide increment and decrement arrows in number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes the default margin */
}

@media screen and (max-width: 1500px) {

    .VIOS__price__prop {
      padding: var(--padding-up-down) 7rem;
    }
  
  }
  
  
  @media screen and (max-width: 1050px) {
  
    .VIOS__price__prop {
      padding: var(--padding-up-down) 0rem;
    }
  
  }
  
  @media screen and (max-width: 800px) {

    .slider-container {
        padding: 5rem 3rem;
    }

    .annual-revenue {
        flex-direction: column;
        align-items: center; /* Center items horizontally */
        justify-content: center;
        width: 100%; /* Ensure the item occupies full width */
        margin-top: 40px;
    }

  }

  @media screen and (max-width: 635px) {

    .slider-container {
        flex-direction: column;
        justify-content: space-between; /* Adjust spacing between sliders */
        padding: 0 0;
    }

    .slider-item {
        position: relative;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    .bar-center {
        margin-bottom: 70px;
    }

    .container-input-revenue {
        margin-top: 1.5rem;
    }

    .VIOS-offer-form {
        margin-top: 50px;
        padding: 0 50px;
    }

  }
  