.VIOS__products {
    font-family: var(--font-family);
    padding: var(--padding-up-down) var(--base-margin);
}

.products-title {
    font-weight: 1000;
    padding-bottom: 1rem;
    color: var(--color-footer);
    font-size: 20px;
}

.subject-frame {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
  }
  
  .subject-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding-left: 2rem;
  }
  
  .toggle-icon {
    font-size: 1rem;
    color: var(--color-footer);
    padding-right: 2rem;
  }
  
  .subject-description {
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 3rem;
  }


@media screen and (max-width: 1500px) {

  .VIOS__products {
    padding: var(--padding-up-down) 7rem;
  }

}


@media screen and (max-width: 1050px) {

  .VIOS__products {
    padding: var(--padding-up-down) 5rem;
  }

}

@media screen and (max-width: 700px) {

  .VIOS__products {
    padding: var(--padding-up-down) 1rem;
  }

}
