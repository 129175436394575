@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  /* --font-family: 'Madera-Regular', sans-serif; */


  --gradient-text: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(22, 107, 102, 1) 33%, rgba(26, 90, 73, 1) 64%, rgba(0, 0, 0, 1) 100%);
  --gradient-contact: linear-gradient(90deg, rgba(68, 255, 208, 1) 0%, rgba(31, 255, 169, 1) 50%, rgba(186, 255, 131, 1) 100%);

  --color-bg: #EDF1F2;
  --color-white: #ffffff;
  --color-footer: #033234;
  --color-hover: #094c4e;
  --color-contact-form: #44ffd0;
  --color-footer-text: rgb(149, 171, 173);

  --base-margin: 15rem;
  --padding-up-down: 7rem;
}