.VIOS__footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family);
    background-color: var(--color-footer);
    flex-direction: row;
    grid-column-gap: 400px;
    color: var(--color-bg);
    padding: var(--padding-up-down) var(--base-margin);
}

.footer__column{
    padding: 20px 0; /* Adjust padding as needed */
    flex: 1;
    text-align: left;
}

.footer__title {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.footer__text {
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--color-footer-text)
}

.footer__text__copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 11px;
    color: var(--color-footer-text);
    margin-bottom: 7px;
}

.VIOS__footer__logo img {
    width: 150px;
    height: auto; 
    margin-bottom: 10px;
}

.social-media {
    margin-top: 20px;
}

.social-media-link {
    display: inline-block;
    margin-right: 10px;
}

.social-media-link:last-child {
    margin-right: 0;
}

.social-media-link svg {
    width: 30px;
    height: 30px;
    color: var(--color-footer-text);
}

.social-media-link:hover svg {
    color: var(--color-contact-form);
}
  

@media screen and (max-width: 1500px) {

    .VIOS__footer {
      padding: var(--padding-up-down) 7rem;
    }
  
}
  
  
@media screen and (max-width: 1050px) {
  
    .VIOS__footer {
      padding: var(--padding-up-down) 5rem;
      grid-column-gap: 400px;
    }
  
}
  
@media screen and (max-width: 800px) {
  
    .VIOS__footer {
      padding: var(--padding-up-down) 5rem;
      grid-column-gap: 100px;
    }
  
}

@media screen and (max-width: 700px) {
  
    .VIOS__footer {
      padding: var(--padding-up-down) 1rem;
      grid-column-gap: 100px;
    }
  
}

@media screen and (max-width: 560px) {
  
    .VIOS__footer {
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
    }
  
}

