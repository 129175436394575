.VIOS__navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    z-index: 6;
}

.VIOS__navbar__links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.VIOS__navbar__logo {
    margin-left: -2rem;
    margin-right: 2rem;
}

.VIOS__navbar__logo img { 
    width: 50px;
}

.VIOS__navbar__links__container {
    display: flex;
    flex-direction: row;
}

.VIOS__navbar__contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.VIOS__navbar__links__container p,
.VIOS__navbar__menu__container p {
    color: var(--color-bg);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.VIOS__navbar__contact .contact-button,
.VIOS__navbar__menu__container .contact-button {
    padding: 0.5rem 1rem;
    color: var(--color-bg);
    background: var(--color-footer);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.VIOS__navbar__menu__container .contact-button {
    background: var(--color-hover);
}

.VIOS__navbar__menu__container .contact-button:hover {
    background: rgb(29, 116, 116);
}

.menu-items::after {
    content: '';
    display: block;
    width: 0; /* Initially, the bar is not visible */
    height: 2px;
    background-color: var(--color-bg); /* Color of the bar */
    transition: width 0.3s; /* Smooth transition for the width */
    margin-top: 3px;
  }

.menu-items:hover::after {
    width: 100%;
}

.VIOS__navbar__contact button:hover,
.VIOS__navbar__menu__container button:hover {
    background: var(--color-hover);
}

.VIOS__navbar__menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.VIOS__navbar__menu svg {
    cursor: pointer;
}

.VIOS__navbar__menu__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    background: var(--color-footer);
    padding: 0.5rem 1rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.VIOS__navbar__menu__container p {
    margin: 1rem 0.2rem;
}

.VIOS__navbar__menu__container__links__contact {
    display: none;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1050px) {
    .VIOS__navbar__links__container {
        display: none;
    }

    .VIOS__navbar__menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .VIOS__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .VIOS__navbar {
        padding: 1rem;
    }

    .VIOS__navbar__menu__container {
        top: 20px;
    }

    .VIOS__navbar__menu__container__links__contact {
        display: block;
    }

    .VIOS__navbar__contact {
        display: none;
    }
    
    .VIOS__navbar__logo {
        margin-left: 0;
    }
}