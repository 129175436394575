/* ContactFormPopup.css */
.contact-form-popup {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-align: center;
  }

.background-header {
  background-color: var(--color-footer);
  border-radius: 5px 5px 0 0;
  padding: 30px 40px;
}

.background-form {
  background-color: #fff; /* White background color */
  padding: 30px 40px;
  border-radius: 0 0 5px 5px; /* Rounded corners at the bottom */
}

.background-header,
.background-form {
  width: 500px;
}

.header_texts h2,
.header_texts p {
  margin-bottom: 15px;
  color: var(--color-white);
}
  
.header_texts h2 {
  font-size: 26px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-family: var(--font-family);
}

.form-group textarea {
  resize: none;
  max-height: 250px;
  min-height: 50px;
}

.cta-button-send {
  background-color: var(--color-footer);
  color: var(--color-white);
  border: var(--color-footer);
  padding: 14px 0px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button-send:hover {
  background-color: var(--color-hover);
}

.privacy-text {
  margin-top: 20px;
  font-size: 12px;
  color: #666;
}

.form-group input {
  font-size: 15px;
}

.background-header .close-button {
  position: relative;
  left: 230px;
  top: -23px;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  color: var(--color-white);
}

.check_icon {
  font-size: 3em;
  color: var(--color-white);
  margin: 1rem 0;
  animation: puff-in-center 0.5s
}

@media screen and (max-width: 550px) {

  .background-header,
  .background-form {
    width: 400px;
  }

  .background-header .close-button {
    left: 180px;
  }
}

@media screen and (max-width: 400px) {

  .background-header,
  .background-form {
    width: 350px;
  }

  .background-header .close-button {
    left: 160px;
    top: 5px;
  }

  .background-header {  
    padding: 0px 0px;
  }

  .privacy-text {
    margin-top: 10px;
    font-size: 9px;
  }

  .background-form {
    padding: 20px 20px;
  }

}

@media screen and (max-width: 350px) {

  .background-header,
  .background-form {
    width: 300px;
  }

  .background-header .close-button {
    left: 130px;
    top: 5px;
  }

  .background-header {  
    padding: 0px 0px;
  }

  .privacy-text {
    margin-top: 10px;
    font-size: 9px;
  }

  .background-form {
    padding: 20px 20px;
  }

}

